<template>
  <div class="center">
  </div>
</template>
<script>

export default {
  props: ['row', 'nested'],
}
</script>
<style>

</style>
